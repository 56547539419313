.tags {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding-bottom: 3rem;
    position: relative;
    padding-right: -60px;

    &__texts {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;

        h4 {
            font-size: $fontSize;
            font-weight: 700;
        }

        p {
            font-size: 10px;
            // padding-top: .6rem;
        }

    }

    &__img {
        height: 30px;
        width: auto;
        background-color: aqua;
        padding: 5px;
        border-radius: 15px;
        margin-left: 1rem;
    }

    &__line {
        width: 92%;
        height: 3rem;
        align-self: flex-start;
        // padding: -2px -10px -2px 0px;
        border-right: $border;
        background-color: transparent;

        :last-child {
            border-right: none;
        }
    }
}