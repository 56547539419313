@import 'utils';
@import 'card';
@import 'header';
@import 'description';
@import 'form';
@import 'input';
@import 'title';
@import 'tags';

:root,
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body {
    display: flex;
}

.blue {
    background-color: rgb(17, 22, 63);
    color: #fff;
}

.tag {
    width: 30%;
}